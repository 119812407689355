// .env
// @ts-ignore
import * as dotenv from 'dotenv'
dotenv.config()

// ant
// @ts-ignore
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

// moment
import moment from "moment"
// @ts-ignore
window.$moment = moment

// css
import "@/v2/app/assets/scss/main.scss"
import "@/assets/sass/app.scss"
import "@/assets/sass/custom/index.scss"

// axios
// @ts-ignore
import axiosInstance from '@/plugins/axios'
// @ts-ignore
window.$axios = axiosInstance

// router
// @ts-ignore
import router from "@/routes"

// vue-tel-input
// @ts-ignore
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

// store
import { createStore } from 'vuex'
// @ts-ignore
import * as cmsModules from '@/store/api_v1/cms'
// @ts-ignore
import * as patientModules from '@/store/api_v1/patient'
// @ts-ignore
import * as emmaModules from '@/store/api_v1/emma'
const store = createStore({
  modules: {
    ...cmsModules,
    ...patientModules,
    ...emmaModules
  }
})
// @ts-ignore
window.$store = store

// Vue
// @ts-ignore
import App from '@/components/App'
// @ts-ignore
import { createApp } from 'vue'
// @ts-ignore
import mitt from 'mitt';
const emitter = mitt();
const app = createApp(App)
app.config.globalProperties.$moment = moment
app.config.globalProperties.emitter = emitter;
app.use(router)
app.use(store)
app.use(Antd)
app.use(VueTelInput)
app.mount('#app')
